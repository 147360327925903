<template>
  <b-modal
    :active="show"
    @close="close"
    has-modal-card
    trap-focus
    :full-screen="isSm || fullscreen"
    :destroy-on-hide="false"
    :can-cancel="['escape', 'outside']"
    aria-role="dialog"
    aria-modal
  >
    <component :is="isForm ? 'form' : 'div'" class="modal-card" @submit.prevent="$emit('submit')">
      <header class="modal-card-head border-b-0">
          <button type="button" @click="close">
            <b-icon icon="menu-left" size="is-large"></b-icon>
          </button>
          <p class="modal-card-title">{{title}}</p>
          <!-- <div @click="close" class="cursor-pointer"><b-icon icon="close" class="bg-gray-100 border border-solid rounded-full p-4 my-auto hover:border-gray-600" custom-size="mdi-24px" type="is-primary"></b-icon></div> -->
      </header>
      <section class="modal-card-body">
        <!-- <div class="map w-full" id="map" style="z-index: 0;height: 300px !important"></div> -->
        <slot></slot>
      </section>
      <footer v-if="closeButton && submitButton" class="modal-card-foot bg-white border-t-0">
          <button v-if="closeButton" class="button" type="button" @click="close">{{closeButton}}</button>
          <button v-if="submitButton" :type="isForm ? 'submit' : 'button'" class="button is-primary">{{submitButton}}</button>
      </footer>
    </component>
  </b-modal>
</template>

<script>
export default {
  mounted () {
    // console.log('this', this.$store.state.modal)
    // var data = {
    //     Latitude: 1.1628328180832364,
    //     Longitude: 104.04505209595521,
    //     Name: 'VA'
    // }
    // this.leafletMap(data)
  },
  props: {
    show: Boolean,
    title: String,
    submitButton: {
      type: [String, Boolean],
      default: 'Save'
    },
    closeButton: {
      type: [String, Boolean],
      default: 'Close'
    },
    fullscreen: Boolean,
    isForm: Boolean
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style>

</style>
